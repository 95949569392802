const Logo: React.FC<{ className: string; onClick: () => void }> = (props) => (
  <svg
    {...props}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M4026 4624 c-67 -21 -147 -102 -168 -169 -12 -36 -20 -134 -28 -340
-6 -159 -15 -293 -18 -297 -14 -13 -363 -220 -366 -217 -2 2 7 159 19 348 13
190 21 361 18 380 -14 87 -81 173 -163 210 -51 23 -147 28 -201 9 -85 -29
-156 -104 -179 -190 -5 -18 -25 -276 -45 -572 -19 -297 -37 -542 -39 -544 -14
-13 -470 -282 -473 -278 -3 2 5 249 17 548 30 762 29 752 1 815 -45 101 -141
163 -251 162 -118 -1 -200 -50 -249 -151 l-31 -63 -32 -825 c-21 -559 -35
-829 -42 -837 -6 -7 -131 -85 -276 -173 l-265 -160 -3 51 c-2 28 4 151 13 273
29 411 73 785 140 1194 19 117 35 232 35 256 0 68 -27 132 -79 185 -128 131
-360 99 -442 -61 -23 -45 -46 -159 -88 -437 -83 -543 -134 -1087 -147 -1571
-4 -124 -11 -230 -16 -236 -5 -7 -101 -67 -214 -135 -144 -86 -217 -136 -245
-167 -91 -104 -90 -252 3 -357 57 -64 124 -95 208 -95 68 0 121 19 208 74 23
14 43 26 45 26 2 0 7 -82 10 -182 7 -226 19 -271 92 -344 64 -64 130 -89 219
-82 78 7 132 34 185 94 69 78 73 109 61 405 -5 140 -10 297 -10 349 l0 95 271
158 c149 87 272 157 274 155 2 -2 -5 -219 -16 -483 -11 -264 -17 -496 -14
-514 30 -164 196 -264 360 -217 79 24 157 100 179 177 12 41 22 212 41 700 14
355 25 655 25 667 0 16 48 49 226 157 124 76 228 134 231 131 4 -3 -20 -401
-52 -884 -47 -717 -56 -885 -46 -919 17 -63 70 -134 125 -168 68 -41 177 -48
254 -15 58 25 123 90 148 147 13 30 31 255 89 1123 41 597 74 1086 74 1087 4
9 374 223 379 219 3 -4 -15 -438 -41 -966 -51 -1068 -52 -1013 16 -1101 137
-178 426 -121 488 95 7 24 119 2187 119 2297 0 24 21 39 253 177 163 97 265
164 288 189 52 58 72 117 67 200 -9 157 -118 263 -271 264 -72 1 -107 -12
-218 -77 -42 -24 -79 -44 -83 -44 -4 0 -6 57 -4 128 2 111 0 133 -19 173 -58
129 -212 196 -347 153z"
      />
    </g>
  </svg>
)

export default Logo
